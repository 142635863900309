import React, { useEffect, useState } from 'react';
import { Switch, Route, Link } from 'react-router-dom';
import './App.css';
import Work from './work';
import Volunteering from './volunteering';
import {
  ABOUT_COLOR,
  EXPERIENCE,
  BANNER,
  VOLUNTEERING,
  PUBLICATION,
  EDUCATION
} from './const';
import { isMobile } from 'react-device-detect';
import Education from './education';
import Publication from './publication';
import useImage from './useImage';
import { Helmet } from 'react-helmet';

function App () {
  const [scrollTop, setScrollTop] = useState(0);
  const [fixedTitle, setFixedTitle] = useState(false);

  const listener = e => {
    let top = e.target.scrollTop;
    if (top > 100) {
      if (!fixedTitle) setFixedTitle(true);
    } else {
      if (fixedTitle) setFixedTitle(false);
    }
    if (top >= 0) setScrollTop(top);
  };

  useEffect(() => {
    window.addEventListener('scroll', listener);
    return () => {
      window.removeEventListener('scroll', listener);
    };
  });

  const { loaded } = useImage({ src: '/assets/nicolas.jpg' });

  return (
    <div className='container-wrapper'>
      <div
        className='section top-banner-fixed'
        style={{
          backgroundColor: BANNER.BACKGROUND
        }}
      >
        <div className='title-section'>
          <div className='title-name'>
            <span className='name'>Nicolas Tremblay</span>
            <p style={{ fontSize: '18px', margin: '0px' }}>Software Engineer</p>
          </div>
          {!isMobile ? <div style={{ flex: 1 }} /> : null}
          <div className='links-container'>
            <Link to='/'>
              <div
                className='section-button'
                style={{
                  backgroundColor: ABOUT_COLOR.BACKGROUND,
                  color: ABOUT_COLOR.TEXT
                }}
              >
                <span
                  style={{ fontSize: !fixedTitle ? 18 - scrollTop * 0.06 : 12 }}
                >
                  About
                </span>
              </div>
            </Link>

            <Link to='/experience'>
              <div
                className='section-button'
                style={{
                  backgroundColor: EXPERIENCE.BACKGROUND,
                  color: EXPERIENCE.TEXT
                }}
              >
                <span
                  style={{ fontSize: !fixedTitle ? 18 - scrollTop * 0.06 : 12 }}
                >
                  Work
                </span>
              </div>
            </Link>
            <Link to='/education'>
              <div
                className='section-button'
                style={{
                  backgroundColor: EDUCATION.BACKGROUND,
                  color: EDUCATION.TEXT
                }}
              >
                <span style={{ fontSize: 12 }}>Education</span>
              </div>
            </Link>
            <Link to='/volunteering'>
              <div
                className='section-button'
                style={{
                  backgroundColor: VOLUNTEERING.BACKGROUND,
                  color: VOLUNTEERING.TEXT
                }}
              >
                <span style={{ fontSize: 12 }}>Volunteer</span>
              </div>
            </Link>
            <Link to='/publication'>
              <div
                className='section-button'
                style={{
                  backgroundColor: PUBLICATION.BACKGROUND,
                  color: PUBLICATION.TEXT
                }}
              >
                <span style={{ fontSize: 12 }}>Publication</span>
              </div>
            </Link>
          </div>
        </div>

        <div className='menu-gradient' />
      </div>
      <div
        style={{
          marginTop: 100,
          width: '100%',
          height: '100%'
        }}
      >
        <Switch>
          <Route path='/experience'>
            <Helmet>
              <title>Nicolas Tremblay - Work Experience</title>
            </Helmet>
            <div
              className='section'
              style={{
                backgroundColor: EXPERIENCE.BACKGROUND,
                color: EXPERIENCE.TEXT,
                minHeight: '100%',
                paddingBottom: 15
              }}
            >
              <Work />
            </div>
          </Route>
          <Route path='/education'>
            <Helmet>
              <title>Nicolas Tremblay - Education</title>
            </Helmet>
            <div
              className='section'
              style={{
                backgroundColor: EDUCATION.BACKGROUND,
                color: EDUCATION.TEXT,
                minHeight: '100%',
                paddingBottom: 15
              }}
            >
              <Education />
            </div>
          </Route>
          <Route path='/volunteering'>
            <Helmet>
              <title>Nicolas Tremblay - Volunteering</title>
            </Helmet>
            <div
              className='section'
              style={{
                backgroundColor: VOLUNTEERING.BACKGROUND,
                color: VOLUNTEERING.TEXT,
                minHeight: '100%',
                paddingBottom: 15
              }}
            >
              <Volunteering />
            </div>
          </Route>
          <Route path='/publication'>
            <Helmet>
              <title>Nicolas Tremblay - Publication</title>
            </Helmet>
            <div
              className='section'
              style={{
                backgroundColor: PUBLICATION.BACKGROUND,
                color: PUBLICATION.TEXT,
                minHeight: '100%',
                paddingBottom: 15
              }}
            >
              <Publication />
            </div>
          </Route>

          <Route path='/'>
            <Helmet>
              <title>Nicolas Tremblay - About</title>
            </Helmet>
            <div
              className='section'
              style={{
                backgroundColor: ABOUT_COLOR.BACKGROUND,
                color: ABOUT_COLOR.TEXT,
                minHeight: '100%',
                paddingBottom: 15
              }}
            >
              <div className='about-section'>
                <img
                  className={`profile smooth ${loaded ? 'loaded' : ''}`}
                  src={'/assets/nicolas.jpg'}
                  alt={'Nicolas Tremblay profile picure'}
                />

                <div style={{ marginLeft: !isMobile ? 25 : 0 }}>
                  <span style={{ fontSize: '28px', fontWeight: 'lighter' }}>
                    About me
                  </span>
                  <p>
                    Welcome to my website! I am a passionate problem solver with
                    over 4 years of post graduate work experience developing
                    enterprise and education applications as part of small and
                    large teams.
                  </p>
                  <p>
                    Being the second engineer at Acadium, I have had the
                    privilege of building and leading the construction of our
                    mobile application. Our application was featured by Apple on
                    multiple occasions and has helped hundreds of thousands of
                    students around the world advance their careers.
                  </p>
                  <p>
                    Prior to working at Acadium, I worked as a Senior Web
                    Developer for the Office of the Auditor General of Canada.
                    Our team built cost effective, cutting edge enterprise
                    solutions on top of government legacy software.
                  </p>

                  <div
                    style={{
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center'
                    }}
                  >
                    <Link to='/experience'>
                      <div
                        className='section-button'
                        style={{
                          backgroundColor: EXPERIENCE.BACKGROUND,
                          color: EXPERIENCE.TEXT,
                          marginTop: 25
                        }}
                      >
                        <span
                          style={{
                            fontSize: !fixedTitle ? 18 - scrollTop * 0.06 : 12
                          }}
                        >
                          Work
                        </span>
                      </div>
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </Route>
        </Switch>
      </div>
    </div>
  );
}

export default App;
