import React from "react";
import Optometry from "./volunteering/optometry";
import Igem from "./volunteering/igem";
import Research from "./volunteering/research";
import { Link } from "react-router-dom";
import { PUBLICATION } from "./const";
function Voluteering() {
  return (
    <div className="body-section">
      <span style={{ fontSize: "28px", fontWeight: "lighter" }}>
        Volunteering
      </span>
      <Optometry />
      <Igem />
      <Research />
      <Link to="/publication">
        <div
          className="section-button"
          style={{
            backgroundColor: PUBLICATION.BACKGROUND,
            color: PUBLICATION.TEXT,
            marginTop:25
          }}
        >
          <span style={{ fontSize: 12 }}>Publication</span>
        </div>
      </Link>
    </div>
  );
}

export default Voluteering;
