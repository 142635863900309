import React from 'react';
import './work.css';

function Acadium () {
  return (
    <div className='section body-section-below-title'>
      <div className='experience-logos'>
        <a
          href='https://acadium.com'
          style={{ cursor: 'pointer' }}
          target='_blank'
          rel='noopener noreferrer'
        >
          <img
            className='experience-company-logo'
            alt='Acadium Logo'
            src='/assets/acadium.png'
          />
        </a>

        <div className='techs-logos'>
          <img
            className='tech-logo'
            src='/assets/react.png'
            alt='React Native Logo'
          />
          <img
            className='tech-logo'
            src='/assets/typescript.png'
            alt='Typescript Logo'
          />
          <img className='tech-logo' src='/assets/aws.png' alt='AWS Logo' />
          <img
            className='tech-logo'
            src='/assets/angularjs.svg'
            alt='AngularJS Logo'
          />
          <img
            className='tech-logo'
            src='/assets/docker.png'
            alt='Docker Logo'
          />
        </div>
      </div>
      <div className='experience-text'>
        <div className='experience-title'>
          <div style={{ flexDirection: 'column' }}>
            <span style={{ fontSize: '22px', fontWeight: 'lighter' }}>
              Senior Software Engineer
            </span>
            <br />
            <span style={{ fontSize: '14px', fontWeight: 'lighter' }}>
              Acadium, Ottawa, Canada
            </span>
          </div>
          <span style={{ fontWeight: 'lighter' }}>04/2018-12/2020</span>
        </div>

        <div className='experience-body'>
          <ul>
            <li>
              Designed, planned and led the development of the mobile
              application's chat, courses, profile editor, onboarding, payment
              and matching features (React Native, Typescript)
            </li>
            <li>
              Built calling functionality using WebRTC and peer to peer
              communication using Java
            </li>
            <li>
              Implemented Firebase Dynamic Linking, Push Notification, A/B
              testing and Analytics to increase mobile app usage from 300 to 30
              000 monthly users
            </li>
            <li>
              Completed the web application's bundler migration from Gulp to
              Webpack
            </li>
            <li>
              Setup QA server using Docker Swarm to enable rapid, reproducible
              manual QA processes
            </li>
            <li>Developed and formalized Acadium's bug reporting system</li>
            <li>
              Implemented SEO/ASO strategies to rank among the top ten marketing
              apps and got featured in the App Store
            </li>
            <li>Recruited and onboarded 4 engineers</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Acadium;
