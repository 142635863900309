import React from 'react';
import Acadium from './work/acadium';
import OAG from './work/OAG';
import PHAC from './work/PHAC';
import ELearning from './work/e-learning';
import Stem from './work/stem';
import UOttawa from './work/uottawa';
import IRCC from './work/IRCC';
import { Link } from 'react-router-dom';
import { EDUCATION } from './const';

function Work () {
  return (
    <div className='body-section'>
      <span style={{ fontSize: '28px', fontWeight: 'lighter' }}>
        Work Experience
      </span>
      <IRCC />
      <Acadium />
      <OAG />
      <PHAC />
      <ELearning />
      <Stem />
      <UOttawa />
      <Link to='/education'>
        <div
          className='section-button'
          style={{
            backgroundColor: EDUCATION.BACKGROUND,
            color: EDUCATION.TEXT,
            marginTop: 25
          }}
        >
          <span style={{ fontSize: 12 }}>Education</span>
        </div>
      </Link>
    </div>
  );
}

export default Work;
