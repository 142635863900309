import React from 'react';
import './work.css';

function PHAC () {
  return (
    <div className='section body-section-below-title'>
      <div className='experience-logos'>
        <a
          href='https://www.canada.ca/en/public-health.html'
          style={{ cursor: 'pointer' }}
          target='_blank'
          rel='noopener noreferrer'
        >
          <img
            className='experience-company-logo'
            alt='PHAC Logo'
            src='/assets/PHAC.jpg'
          />
        </a>

        <div className='techs-logos'>
          <img className='tech-logo' src='/assets/perl.png' alt='Perl Logo' />
          <img
            className='tech-logo'
            src='/assets/angularjs.svg'
            alt='AngularJS Logo'
          />
        </div>
      </div>
      <div className='experience-text'>
        <div className='experience-title'>
          <div style={{ flexDirection: 'column' }}>
            <span style={{ fontSize: '22px', fontWeight: 'lighter' }}>
              Co-op Bioinformatics Research Technician
            </span>
            <br />
            <span style={{ fontSize: '14px', fontWeight: 'lighter' }}>
              Public Health Agency of Canada, Lethbridge, Canada
            </span>
          </div>
          <span style={{ fontWeight: 'lighter' }}>04/2015 - 08/2015</span>
        </div>

        <div className='experience-body'>
          <ul>
            <li>Used Perl to develop an algorithm to mine genome metadata.</li>
            <li>
              <a
                href='https://lfz.corefacility.ca/superphy/'
                style={{ color: 'red' }}
                target='_blank'
                rel='noopener noreferrer'
              >
                https://lfz.corefacility.ca/superphy/
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default PHAC;
