export const ABOUT_COLOR = {
  BACKGROUND: "#ffffff",
  TEXT: "black",
};
export const EXPERIENCE = {
  BACKGROUND: "#0D47A1",
  TEXT: "white",
};

export const BANNER = {
  BACKGROUND: "#F5F5F5",
  TEXT: "black"
};
export const VOLUNTEERING = {
  BACKGROUND: "#E65100",
  TEXT: "white"
};
export const EDUCATION = {
  BACKGROUND: "#212121",
  TEXT: "white"
};
export const PUBLICATION = {
  BACKGROUND: "#00B8D4",
  TEXT: "white"
};

