import React from "react";
import "../work/work.css";

function research() {
  return (
    <div className="section body-section-below-title">
      <div className="experience-logos">
      <a
          href="https://www.uottawa.ca/en"
          style={{ cursor: "pointer" }}
          target="_blank"
          rel='noopener noreferrer'
        >
          <img
            className="experience-company-logo"
            alt="University of Ottawa logo"
            src="/assets/uottawa.png"
          ></img>
        </a>
      </div>
      <div className="experience-text">
        <div className="experience-title">
          <div style={{ flexDirection: "column" }}>
            <span style={{ fontSize: "22px", fontWeight: "lighter" }}>
              Clinical Trial Researcher
            </span>
            <br />
            <span style={{ fontSize: "14px", fontWeight: "lighter" }}>
              Faculty of Science, uOttawa, Ottawa, Canada
            </span>
          </div>
          <span style={{ fontWeight: "lighter" }}>06/2013-11/2013</span>
        </div>

        <div className="experience-body">
          <ul>
            <li>
              Collected data from multiple clinical trials in order to find best
              drug cocktail for cancer treatment
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default research;
