import React from "react";

function Publication() {
  return (
    <div className="body-section">
      <span style={{ fontSize: "28px", fontWeight: "lighter" }}>
        Publication
      </span>
      <div className="section body-section-below-title">
        <span>
          Nicolas Tremblay, Patrick B. Hill, Kyle R. Conway, Christopher N.
          Boddy, The use of ClusterMine360 for the analysis of polyketide and
          non-ribosomal peptide biosynthetic pathways, Nonribosomal Peptide and
          Polyketide Biosynthesis, December 2015
        </span>
      </div>
    </div>
  );
}

export default Publication;
