import React from 'react';
import '../work/work.css';

function iGEM () {
  return (
    <div className='section body-section-below-title'>
      <div className='experience-logos'>
        <a
          href='http://2013.igem.org/Team:uOttawa/modeling'
          style={{ cursor: 'pointer' }}
          target='_blank'
          rel='noopener noreferrer'
        >
          <img
            href='http://2013.igem.org/Team:uOttawa/modeling'
            className='experience-company-logo'
            alt='Acadium Logo'
            src='/assets/igem.png'
            style={{
              height: 150,
              width: 'auto',
              backgroundColor: 'black',
              borderRadius: 20
            }}
          />
        </a>
      </div>
      <div className='experience-text'>
        <div className='experience-title'>
          <div style={{ flexDirection: 'column' }}>
            <span style={{ fontSize: '22px', fontWeight: 'lighter' }}>
              Mathematical Modeler
            </span>
            <br />
            <span style={{ fontSize: '14px', fontWeight: 'lighter' }}>
              uOttawa iGEM, Ottawa, Canada
            </span>
          </div>
          <span style={{ fontWeight: 'lighter' }}>06/2013-11/2013</span>
        </div>

        <div className='experience-body'>
          <ul>
            <li>
              Extracted and analysed data from high throughput experiments
            </li>
            <li>Won gold medal in North American championship</li>
            <li>
              <a
                href='http://2013.igem.org/Team:uOttawa/modeling'
                target='_blank'
                style={{ color: 'white' }}
                rel='noopener noreferrer'
              >
                Link to mathematical model and demo
              </a>
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default iGEM;
