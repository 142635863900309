import React from 'react';
import './work.css';

function Stem () {
  return (
    <div className='section body-section-below-title'>
      <div className='experience-logos'>
        <a
          href='https://med.uottawa.ca/en'
          style={{ cursor: 'pointer' }}
          target='_blank'
          rel='noopener noreferrer'
        >
          <img
            className='experience-company-logo'
            alt='University of Ottawa Faculty of Medicine logo'
            src='/assets/medicine.png'
          />
        </a>
      </div>
      <div className='experience-text'>
        <div className='experience-title'>
          <div style={{ flexDirection: 'column' }}>
            <span style={{ fontSize: '22px', fontWeight: 'lighter' }}>
              Summer research student
            </span>
            <br />
            <span style={{ fontSize: '14px', fontWeight: 'lighter' }}>
              Faculty of Medicine, University of Ottawa, Ottawa, Canada
            </span>
          </div>
          <span style={{ fontWeight: 'lighter' }}>05/2013 - 09/2013</span>
        </div>

        <div className='experience-body'>
          <ul>
            <li>
              Studied stem cell differentiation into skeletal and cardiac muscle
              cells
            </li>
            <li>Presented work in front of fellow researchers</li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Stem;
