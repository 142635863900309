import React from 'react';
import './work.css';

function ELearning () {
  return (
    <div className='section body-section-below-title'>
      <div className='experience-logos'>
        <a
          href='https://www.canada.ca/en/public-health.html'
          style={{ cursor: 'pointer' }}
          target='_blank'
          rel='noopener noreferrer'
        >
          <img
            className='experience-company-logo'
            alt='Statistics Canada Logo'
            src='/assets/stats.png'
          />
        </a>

        <div className='techs-logos'>
          <img
            className='tech-logo'
            src='/assets/jquery.png'
            alt='Jquery Logo'
          />
          <img className='tech-logo' src='/assets/asp.png' alt='ASP Logo' />
        </div>
      </div>
      <div className='experience-text'>
        <div className='experience-title'>
          <div style={{ flexDirection: 'column' }}>
            <span style={{ fontSize: '22px', fontWeight: 'lighter' }}>
              E-learning programmer
            </span>
            <br />
            <span style={{ fontSize: '14px', fontWeight: 'lighter' }}>
              Statistics Canada, Ottawa, Canada
            </span>
          </div>
          <span style={{ fontWeight: 'lighter' }}>01/2016 - 12/2016</span>
        </div>

        <div className='experience-body'>
          <ul>
            <li>
              Consulted with subject matter experts in order to develop
              compelling E-learning courses
            </li>
            <li>
              Worked with the business transformation division to help map new
              business processes.
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default ELearning;
