import React from 'react';
import './work.css';

function IRCC () {
  return (
    <div className='section body-section-below-title'>
      <div className='experience-logos'>
        <a
          href='https://www.canada.ca/en/immigration-refugees-citizenship.html'
          style={{ cursor: 'pointer' }}
          target='_blank'
          rel='noopener noreferrer'
        >
          <img
            className='experience-company-logo'
            alt='IRCC logo'
            src='/assets/IRCC.jpg'
          />
        </a>

        <div className='techs-logos'>
          <img
            className='tech-logo'
            src='/assets/angular.png'
            alt='Angular Logo'
          />
          <img
            className='tech-logo'
            src='/assets/typescript.png'
            alt='Typescript Logo'
          />
          <img className='tech-logo' src='/assets/aws.png' alt='AWS Logo' />
          <img
            className='tech-logo'
            src='/assets/docker.png'
            alt='Docker Logo'
          />
        </div>
      </div>
      <div className='experience-text'>
        <div className='experience-title'>
          <div style={{ flexDirection: 'column' }}>
            <span style={{ fontSize: '22px', fontWeight: 'lighter' }}>
              Programmer Analyst
            </span>
            <br />
            <span style={{ fontSize: '14px', fontWeight: 'lighter' }}>
              Immigration, Refugees and Citizenship Canada
            </span>
          </div>
          <span style={{ fontWeight: 'lighter' }}>03/2021-present</span>
        </div>

        <div className='experience-body'>
          <ul>
            <li>
              Built a Weblate server using Docker to manage translations in the
              TR application (Artifactory, Docker, Docker Compose and container
              orchestration)
            </li>
            <li>Migrated the TR application from Angular 9 to Angular 11</li>
            <li>
              Developed a Docker development environment, increasing the speed
              of developer's development environment by a factor of two.
            </li>
            <li>
              Supporting the TR team by fixing bugs and reducing technical debt
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default IRCC;
