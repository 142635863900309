import React from "react";
import { Link } from "react-router-dom";
import { VOLUNTEERING } from "./const"
function Education() {
  return (
    <div className="body-section">
      <span style={{ fontSize: "28px", fontWeight: "lighter" }}>Education</span>
      <div className="section body-section-below-title">
        <div className="experience-text">
          <div className="experience-title">
            <div style={{ flexDirection: "column" }}>
              <span style={{ fontSize: "22px", fontWeight: "lighter" }}>
                Software Engineering (BASc)
              </span>
              <br />
              <span style={{ fontSize: "14px", fontWeight: "lighter" }}>
                University of Ottawa, Ottawa, Canada
              </span>
            </div>
            <span style={{ fontWeight: "lighter" }}>2013-2016</span>
          </div>
        </div>
      </div>
      <div className="section body-section-below-title">
        <div className="experience-text">
          <div className="experience-title">
            <div style={{ flexDirection: "column" }}>
              <span style={{ fontSize: "22px", fontWeight: "lighter" }}>
                Bachelor of Science with Minor in Biochemistry (B.Sc.)
              </span>
              <br />
              <span style={{ fontSize: "14px", fontWeight: "lighter" }}>
                University of Ottawa, Ottawa, Canada
              </span>
            </div>
            <span style={{ fontWeight: "lighter" }}>2010-2013</span>
          </div>
        </div>
      </div>
      <Link to="/volunteering">
        <div
          className="section-button"
          style={{
            backgroundColor: VOLUNTEERING.BACKGROUND,
            color: VOLUNTEERING.TEXT,
            marginTop:25
          }}
        >
          <span style={{ fontSize: 12 }}>Volunteer</span>
        </div>
      </Link>
    </div>
  );
}

export default Education;
