import React from "react";
import "../work/work.css";

function Optometry() {
  return (
    <div className="section body-section-below-title">
      <div className="experience-logos">
        <img
          className="experience-company-logo"
          alt="Acadium Logo"
          src="/assets/touraine.png"
          style={{ height: 150, width: "auto" }}
        ></img>

        <img
          className="tech-logo"
          src="/assets/angularjs.svg"
          alt="AngularJS Logo"
        ></img>
      </div>
      <div className="experience-text">
        <div className="experience-title">
          <div style={{ flexDirection: "column" }}>
            <span style={{ fontSize: "22px", fontWeight: "lighter" }}>
              Software Developer
            </span>
            <br />
            <span style={{ fontSize: "14px", fontWeight: "lighter" }}>
              Clinique d’Optométrie De Touraine, Gatineau, Canada
            </span>
          </div>
          <span style={{ fontWeight: "lighter" }}>01/2016-01/2017</span>
        </div>

        <div className="experience-body">
          <ul>
            <li>
              Developed iPad application to enable optometrist to fill in
              patient eye exam results
            </li>
          </ul>
        </div>
      </div>
    </div>
  );
}

export default Optometry;
